import ListItem from '@material-ui/core/ListItem'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { useProductDetail } from '@bloomreach/connector-components-react'
import { useCookies } from 'react-cookie'
import {
    string, object, bool, number
} from 'prop-types'
import { useContext, useMemo } from 'react'
import RemoveFromCartButton from '../RemoveFromCartButton/RemoveFromCartButton'
import UpdateCartItemForm from '../UpdateCartItemForm/UpdateCartItemForm'
import ProductLink from '../../Product/ProductLink'
import useStyles from './style'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import DisplayPrice from '../../Product/DisplayPrice/DisplayPrice'
import { getVarAttrs } from '../../../../lib/utils'
import storeKeys from '../../../_Mappings/storeKeys'
import { COOKIE_LOCATION } from '../../../_Mappings/cookies'
import channelKeys from '../../../_Mappings/channelKeys'
import useChannelKeyWorkaround from '../useChannelKeyWorkaround'
import { GoogleEnhancedEcommerce } from '../../ThirdPartyScripts/GoogleEnhancedEcommerce'
import MoveToWishlistButton from '../MoveToWishlistButton/MoveToWishlistButton'

const CartItem = ({
    cartId, entry, isCart, position
}) => {
    const {
        items, quantity, listPrice, id
    } = entry
    const item = items?.[0]
    const { itemId } = item
    const [cookies] = useCookies([COOKIE_LOCATION])
    const location = cookies[COOKIE_LOCATION] ?? ''
    const { storeKey } = storeKeys[location] || {}
    // TODO: remove when bloomreach implements channelKey as param
    const distributionChannel = channelKeys[storeKey]
    const distributionChannelWorkaround = useChannelKeyWorkaround(distributionChannel)

    const { pushRemoveFromToCart, prepareProductForDataLayer } = useContext(GoogleEnhancedEcommerce)

    const [_item] = useProductDetail({
        itemId,
        storeKey
    })
    const classes = useStyles()
    const translations = useTranslation()
    const [sku] = itemId.code.split('-')

    const { colour1Hex, colour2Hex, size } = getVarAttrs(item?.__typename === 'ItemVariant' ? item : item.variants?.[0])

    const imageLink = useMemo(() => {
        const link = _item?.imageSet?.thumbnail?.link.href
        return link ? `/_next/image?url=${encodeURI(link)}&w=768&q=90` : ''
    }, [_item])

    const successFulRemoveHandler = () => {
        const product = prepareProductForDataLayer(
            item,
            {
                id: sku,
                quantity,
                variant: size
            }
        )

        pushRemoveFromToCart(product)
    }

    if (!item) return null

    return (
        <ListItem className={classes.root}>
            <Card className={classes.card} elevation={0}>
                <ProductLink item={_item} position={position}>
                    <CardMedia
                        component='img'
                        className={classes.media}
                        image={imageLink}
                        title={item.displayName ?? ''}
                    />
                </ProductLink>
                <div className={classes.content}>
                    <div>
                        <ProductLink item={_item} position={position}>
                            <Typography variant='subtitle2'>{item.displayName}</Typography>
                        </ProductLink>
                        {colour1Hex && (
                            <Typography variant='body2' className={classes.colorInfo}>
                                {translations('product:color')}
                                {' '}
                                {colour2Hex
                                    ? `${translations(`colors:${colour1Hex}`)}-${translations(`colors:${colour2Hex}`)}`
                                    : translations(`colors:${colour1Hex}`)}
                            </Typography>
                        )}
                        {size && (
                            <Typography variant='body2' className={classes.info}>
                                {`${translations('checkout:product.size')} ${size}`}
                            </Typography>
                        )}
                        <Typography variant='body2' className={classes.info}>
                            {`${translations('checkout:product.code')} ${sku}`}
                        </Typography>
                    </div>
                    {quantity && (isCart ? (
                        <div className={classes.updateProductContainer}>
                            <UpdateCartItemForm
                                cartId={cartId}
                                entry={entry}
                                storeKey={storeKey}
                                distributionChannel={distributionChannelWorkaround}
                            />
                            <RemoveFromCartButton
                                cartId={cartId}
                                entryId={id}
                                storeKey={storeKey}
                                distributionChannel={distributionChannelWorkaround}
                                onSuccessRemove={successFulRemoveHandler}
                            />
                            <MoveToWishlistButton 
                                cartId={cartId}
                                entryId={id}
                                product={item}
                                storeKey={storeKey}
                                distributionChannel={distributionChannelWorkaround}
                            />
                        </div>
                    ) : (
                        <Typography variant='body2'>
                            {`${translations('checkout:product.quantity')} ${quantity}`}
                        </Typography>
                    ))}
                </div>
                <div className={classes.priceContainer}>
                    <div className={classes.price}>
                        <Typography variant='body1'>
                            {translations('checkout:unit.price')}
                        </Typography>
                        <DisplayPrice item={item} className={classes.displayPrice} isCartItem />
                    </div>

                    <div className={classes.price}>
                        <Typography variant='body1' className={classes.subtotal}>
                            {translations('checkout:subtotal')}
                        </Typography>
                        <Typography variant='subtitle2' className={classes.subtotal}>
                            {listPrice?.moneyAmounts ? listPrice.moneyAmounts[0]?.displayValue : ''}
                        </Typography>
                    </div>
                </div>
            </Card>
        </ListItem>
    )
}

CartItem.propTypes = {
    cartId: string,
    entry: object.isRequired,
    isCart: bool,
    position: number
}

CartItem.defaultProps = {
    cartId: undefined,
    isCart: false,
    position: undefined
}

export default CartItem
