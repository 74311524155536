import { useRemoveFromCart } from '@bloomreach/connector-components-react'
import { useContext, useState } from 'react'
import { object, PropTypes } from 'prop-types'
import FormHelperText from '@material-ui/core/FormHelperText'
import { CircularProgress, Link } from '@material-ui/core'
import { SFMCCollectJs } from '../../ThirdPartyScripts/SFMCCollectJs'
import { useCurrentCustomer } from '@bloomreach/connector-components-react'
import { useMutation, useQuery } from '@apollo/client'
import useTranslation from '../../../_Elements/ResourceBundles/useTranslation'
import { getLoginStatus } from '../../../../lib/utils'
import useStyles from './style'
import WISHLIST_QUERY from '../../../_Hooks/useWishlist/WISHLIST_QUERY'
import ADD_ENTRY_TO_WISHLIST_MUTATION from '../../../_Hooks/useWishlist/ADD_ENTRY_TO_WISHLIST_MUTATION'
import { GoogleEnhancedEcommerce } from '../../../_Modules/ThirdPartyScripts/GoogleEnhancedEcommerce'

const MoveToWishlistButton = ({
    cartId, entryId, product, storeKey, distributionChannel
}) => {
    const classes = useStyles()
    const translations = useTranslation()
    const { pushCartActivity, clearCart } = useContext(SFMCCollectJs)
    const [removeFromCart, , , error] = useRemoveFromCart({
        cartId,
        storeKey,
        distributionChannel
    })
    const [loading, setLoading] = useState(false)
    const [user] = useCurrentCustomer()
    const { data } = useQuery(WISHLIST_QUERY, {
        onCompleted: () => { if (loading) setLoading(() => false) }
    })
    const wishlist = data?.findWishlists.wishlists[0] ?? {}

    const productEntry = wishlist?.entries?.find((e) => e?.items?.some((i) => i.itemId.id.includes(product.mainItem.itemId.id)))
    const productIsInWishlist = !!productEntry

    const [addProductToWishlist] = useMutation(ADD_ENTRY_TO_WISHLIST_MUTATION, {
        variables: {
            wishlistId: wishlist.id,
            itemId: product.mainItem.itemId.id
            // customAttrs: product.customAttrs?.map(({ __typename, ...attrs }) => attrs)
        },
        refetchQueries: [WISHLIST_QUERY]
    })
    const { prepareProductForDataLayer, pushAddToWishList } = useContext(GoogleEnhancedEcommerce)
    const handleOnClick = async () => {
        
        if (getLoginStatus(user) === 'Guest'){
            const urlElements = window.location.href.split('/')
            const lang = (urlElements[3])
            if (lang == "fr" || lang == "it" || lang == "ru"){
                window.location.href = '/' + lang + '/signin?returnUrl='+ window.location.pathname
            } else {
                window.location.href = '/signin?returnUrl='+ window.location.pathname
            }
            return
        }
        if (loading) return
        setLoading(true)

        const response = await removeFromCart({ entryId })
        if (response?.success) {
            if(response.cart.entries.length > 0){
                pushCartActivity(response.cart.entries)
            }
            else{
                clearCart()
            }
            if (!productIsInWishlist) {
                await addProductToWishlist()
                pushAddToWishList(prepareProductForDataLayer(product))
            }
        }
        setLoading(false)
    }

    return (
        <>
            <Link
                onClick={handleOnClick}
                className={classes.button}
            >
                {translations('checkout:whishlist.moveTo')}
                {loading && (
                <div className={classes.loadingSpinner}>
                    <CircularProgress size={30} />
                </div>
            )}
            </Link>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
        </>
    )
}

const propTypes = {
    cartId: PropTypes.string,
    entryId: PropTypes.string,
    product: object.isRequired,
    storeKey: PropTypes.string,
    distributionChannel: PropTypes.string
}

const defaultProps = {
    cartId: undefined,
    entryId: undefined,
    distributionChannel: undefined,
    storeKey: undefined
}

MoveToWishlistButton.propTypes = propTypes
MoveToWishlistButton.defaultProps = defaultProps

export default MoveToWishlistButton
