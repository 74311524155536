import makeStyles from '@material-ui/styles/makeStyles'

export default makeStyles(() => ({
    button: {
        paddingBottom: 7,
        alignContent: 'end',
        cursor: 'pointer',
        '&:hover': {
            background: 'none'
        }
    },
    loadingSpinner: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    }
}))
